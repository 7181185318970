<template>
  <div class="radio-background" :class="{ 'd-flex': flex }">
    <div
      v-for="radio in items"
      :key="radio.value"
      class="radio-content"
      @click="Radio_Change(radio.value)"
    >
      <div
        class="radio-bg"
        :class="value === radio.value ? 'isActiveRadio' : ''"
        :style="`width: ${radio.size}px; height: ${radio.size}px;`"
      >
        <div
          v-if="value === radio.value"
          class="radio-dot"
          :style="`width: ${radio.size - 8}px; height: ${radio.size - 8}px;`"
        ></div>
      </div>
      <div :class="value === radio.value ? 'choseRadio' : ''">
        {{ radio.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputRadio",
  mixins: [],
  props: [
    "value",
    "label",
    "readonly",
    "tooltip",
    "keyAdd",
    "defaultValue",
    "required",
    "items",
    "emit",
    "flex"
  ],
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    Radio_Change(val) {
      let data = {
        eventName: "Radio_Change",
        data: {
          event: event,
          value: this.value,
          label: this.label,
          readonly: this.readonly,
          tooltip: this.tooltip,
          keyAdd: this.keyAdd,
          defaultValue: this.defaultValue,
          required: this.required,
          emit: this.emit
        }
      };

      this.$root.$emit(`radio-button:change_${this.keyAdd}`, val);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";

.radio-background {
  display: flex;
  flex-direction: column;

  .radio-content {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    .radio-bg {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid $color-text-secondary;
    }

    .isActiveRadio {
      border: 1px solid $color-interactive;
      background-color: $color-interactive;

      .radio-dot {
        border-radius: 50%;
        background-color: $color-charts-grey-3;
        margin: 0;
      }
    }

    div {
      margin-left: 6px;
      font-style: normal;
      font-size: 14px;
      line-height: 15px;
      color: $color-text-secondary;
    }

    .choseRadio {
      color: $color-text-primary-2;
    }
  }

  .radio-content:hover {
    .radio-bg {
      border: 1px solid $color-interactive;
    }
  }
}

.d-flex {
  flex-direction: row;

  .radio-content {
    margin-right: 12px;
  }
}
</style>
